/**
 * router/index.ts
 *
 * Automatic routes for `./src/pages/*.vue`
 */

// Composables
import { createRouter, createWebHistory, RouteLocationNormalized } from 'vue-router';
import {  authorize } from '../modules/middleware';
import { workspaceId, useWorkspaceStore } from '@/store/workspaces';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      component: () => import('@/layouts/default.vue'),
      children: [
        {
          path: '',
          name: 'home',
          component: () => import('@/pages/home/HomeView.vue'),
          meta: {
            title: 'Home',
            metaTags: [
              {
                name: 'description',
                content: 'Jaily is a powerful application: manage your game with ease, have your moderation out of Roblox and Discord. Free yourself from studio plugins !'
              },
              {
                property: 'og:description',
                content: 'Jaily is a powerful application: manage your game with ease, have your moderation out of Roblox and Discord. Free yourself from studio plugins !'
              }
            ]
          },
        },
        {
          path: '/pricing',
          name: 'pricing',
          component: () => import('@/pages/pricing/PricingView.vue'),
          meta: {
            title: 'Pricing',
            metaTags: [
              {
                name: 'description',
                content: 'Welcome on the pricing page !'
              },
              {
                property: 'og:description',
                content: 'Welcome on the pricing page !'
              }
            ]
          }
        },
        {
          path: '/pricing/verify/:sessionId',
          name: 'verify',
          component: () => import('@/pages/pricing/VerifyView.vue'),
          meta: { title: 'Payment Verification' }
        },
        {
          path: '/cart/:productId',
          name: 'cart',
          component: () => import('@/pages/pricing/CartView.vue'),
          beforeEnter: checkLogin,
          meta: { title: 'Shopping cart' }
        },
        {
          path: '/free/:productId',
          name: 'free-cart',
          component: () => import('@/pages/pricing/CartViewFree.vue'),
          beforeEnter: checkLogin,
          meta: { title: 'Shopping cart' }
        },
        {
          path: '/reviews',
          name: 'reviews',
          component: () => import('@/pages/reviews/ReviewsView.vue'),
          meta: { title: 'Reviews' }
        },
        {
          path: '/legal',
          name: 'legal',
          component: () => import('@/pages/legal/terms.vue'),
          meta: { title: 'Website Terms' }
        },
        {
          path: '/legal/terms',
          name: 'terms',
          component: () => import('@/pages/legal/terms.vue'),
          meta: { title: 'Website Terms' }
        },
        {
          path: '/legal/sales',
          name: 'sales',
          component: () => import('@/pages/legal/sales.vue'),
          meta: { title: 'General Conditions of Sale' }
        },
        {
          path: '/legal/privacy-policy',
          name: 'privacy-policy',
          component: () => import('@/pages/legal/privacy-policy.vue'),
          meta: { title: 'Privacy Policy' }
        },
      ],
    },
    {
      path: '/dashboard/:workspaceId',
      component: () => import('@/layouts/dashboard.vue'),
      beforeEnter: checkLogin,
      children: [
        {
          path: '',
          name: 'dashboard',
          component: () => import('@/pages/dashboard/HomeView.vue'),
          meta: { title: 'Dashboard' },
          beforeEnter: authorize
        },
        {
          path: 'bans/list',
          name: 'bans-list',
          component: () => import('@/pages/bans/BansListView.vue'),
          meta: { title: 'Bans List', requiredAuth: 'BANS_READ' },
          beforeEnter: authorize
        },
        {
          path: 'bans/historic',
          name: 'bans-historic',
          component: () => import('@/pages/bans/BansHistoricView.vue'),
          meta: { title: 'Bans Historic', requiredAuth: 'BANS_READ' },
          beforeEnter: authorize
        },
        {
          path: 'datastores/list',
          name: 'datastores-list',
          component: () => import('@/pages/datastores/DatastoresListView.vue'),
          meta: { title: 'Datastore List', requiredAuth: 'DATASTORE_READ' },
          beforeEnter: authorize
        },
        {
          path: 'datastores/fast-editor',
          name: 'fast-editor',
          component: () => import('@/pages/datastores/FastEditorView.vue'),
          meta: { title: 'Fast Editor', requiredAuth: 'DATASTORE_READ' },
          beforeEnter: authorize
        },
        {
          path: 'datastores/editor',
          name: 'datastore-editor-redirect',
          redirect: '/dashboard/datastores/list'
        },
        {
          path: 'datastores/editor/:name',
          name: 'datastore-editor',
          component: () => import('@/pages/datastores/DatastoreEditorView.vue'),
          meta: { title: 'Datastore Editor', requiredAuth: 'DATASTORE_MODIFY' },
          beforeEnter: authorize
        },
        {
          path: 'kick',
          name: 'kick',
          component: () => import('@/pages/interactions/KickView.vue'),
          meta: { title: 'Kick Player', requiredAuth: 'INTERACTIONS_KICK_PLAYER' },
          beforeEnter: authorize
        },
        {
          path: 'find',
          name: 'find',
          component: () => import('@/pages/interactions/FindView.vue'),
          meta: { title: 'Find Player', requiredAuth: 'INTERACTIONS_FIND_PLAYER' },
          beforeEnter: authorize
        },
        {
          path: 'servers-notify',
          name: 'servers-notify',
          component: () => import('@/pages/interactions/ServersNotifierView.vue'),
          meta: { title: 'Notify Servers', requiredAuth: 'INTERACTIONS_NOTIFY_SERVER' },
          beforeEnter: authorize
        },
        {
          path: 'admin',
          children: [
            {
              path: '',
              name: 'settings',
              component: () => import('@/pages/admin/Base/HomeView.vue'),
              meta: { title: 'Workspace Settings', requiredAuth: 'WORKSPACE_READ', authorizeDisable: true },
              beforeEnter: authorize,
            },
            {
              path: 'users',
              name: 'users',
              component: () => import('@/pages/admin/Users/UsersListView.vue'),
              meta: { title: 'Workspace Members', requiredAuth: 'WORKSPACE_MEMBERS_LIST' },
              beforeEnter: authorize
            },
            {
              path: 'workspaces',
              name: 'workspaces',
              component: () => import('@/pages/admin/Workspaces/WorkspacesListView.vue'),
              meta: { title: 'Workspace Members', requiredAuth: 'WORKSPACE_MEMBERS_LIST' },
              beforeEnter: authorize
            }
          ]
        },
        {
          path: 'create-function',
          name: 'create-function',
          component: () => import('@/pages/custom-functions/CreateFunctionView.vue'),
          meta: { title: 'Create Function', requiredAuth: 'FUNCTIONS_ADD' },
          beforeEnter: authorize
        },
        {
          path: 'use-function/:id',
          name: 'use-function',
          component: () => import('@/pages/custom-functions/UseFunctionView.vue'),
          meta: { title: 'Use Function', requiredAuth: 'FUNCTIONS_READ' },
          beforeEnter: authorize
        },
        {
          path: 'edit-function/:id',
          name: 'edit-function',
          component: () => import('@/pages/custom-functions/EditFunctionView.vue'),
          meta: { title: 'Edit Function', requiredAuth: 'FUNCTIONS_ADD' },
          beforeEnter: authorize
        },
        {
          path: 'transfer',
          name: 'transfer',
          component: () => import('@/pages/workspace_settings/transferView.vue'),
          meta: { title: 'Transfer Workspace' },
          beforeEnter: authorize
        },
        {
          path: 'delete',
          name: 'delete',
          component: () => import('@/pages/workspace_settings/deleteView.vue'),
          meta: { title: 'Confirm delete Workspace' },
          beforeEnter: authorize
        },
        {
          path: 'settings',
          component: () => import('@/layouts/workspaceSettings.vue'),
          children: [
            {
              path: '',
              name: 'settings',
              component: () => import('@/pages/workspace_settings/SettingsView.vue'),
              meta: { title: 'Workspace Settings', requiredAuth: 'WORKSPACE_READ', authorizeDisable: true },
              beforeEnter: authorize,
            },
            {
              path: 'members',
              name: 'members',
              component: () => import('@/pages/workspace_settings/MembersListView.vue'),
              meta: { title: 'Workspace Members', requiredAuth: 'WORKSPACE_MEMBERS_LIST' },
              beforeEnter: authorize
            },
            {
              path: 'ranks',
              name: 'ranks',
              component: () => import('@/pages/workspace_settings/RanksView.vue'),
              meta: { title: 'Workspace Ranks', requiredAuth: 'RANKS_READ' },
              beforeEnter: authorize
            },
            {
              path: 'subscription',
              name: 'subscription',
              component: () => import('@/pages/workspace_settings/SubscriptionView.vue'),
              meta: { title: 'Workspace Subscription', requiredAuth: 'WORKSPACE_MODIFY', authorizeDisable: true, noOwnerRedirect: 'settings' },
              beforeEnter: authorize
            }
          ]
        },
        {
          path: 'upgrade',
          name: 'upgrade',
          component: () => import('@/pages/upgrade/UpgradeView.vue'),
          meta: { title: 'Upgrade your workspace', requiredAuth: 'WORKSPACE_MODIFY', authorizeDisable: true, noOwnerRedirect: 'settings' },
          beforeEnter: authorize
        },
        {
          path: 'upgrade/free',
          name: 'upgradeFree',
          component: () => import('@/pages/upgrade/upgradeFreeView.vue'),
          meta: { title: 'Upgrade your workspace', requiredAuth: 'WORKSPACE_MODIFY', authorizeDisable: true, noOwnerRedirect: 'settings' },
          beforeEnter: authorize
        },
        {
          path: 'profil',
          component: () => import('@/layouts/profile.vue'),
          children: [
            {
              path: '',
              name: 'profil',
              component: () => import('@/pages/profile/ProfileView.vue'),
              meta: { title: 'Profile', authorizeDisable: true },
              beforeEnter: authorize,
            },
            {
              path: 'invoices',
              name: 'invoices',
              component: () => import('@/pages/invoices/InvoicesListView.vue'),
              meta: { title: 'Invoices', authorizeDisable: true },
              beforeEnter: authorize
            }
          ]
        },
        {
          path: 'disable',
          name: 'disable',
          component: () => import('@/pages/disable/DisableView.vue'),
          meta: { title: 'Workspace disabled', authorizeDisable: true },
          beforeEnter: authorize
        },
      ],
    },
    {
      path: '/profil',
      component: () => import('@/layouts/profileGlobal.vue'),
      children: [
        {
          path: '',
          name: 'global-profil',
          component: () => import('@/pages/profile/ProfileView.vue'),
          meta: { title: 'Profil', authorizeDisable: true }
        },
        {
          path: 'invoices',
          name: 'global-invoices',
          component: () => import('@/pages/invoices/InvoicesListView.vue'),
          meta: { title: 'Invoices', authorizeDisable: true }
        }
      ]
    },
    {
      path: '/no-workspace',
      name: 'no-workspace',
      component: () => import('@/pages/no-workspace/NoWorkspaceView.vue'),
      meta: { title: 'No Workspace' },
      beforeEnter: checkLogin,
    },
    {
      path: '/auth',
      component: () => import('@/layouts/empty.vue'),
      beforeEnter: checkToken,
      children: [
        {
          path: 'login',
          name: 'login',
          component: () => import('@/pages/auth/LoginView.vue'),
          meta: { title: 'Login' },
        },
        {
          path: 'register',
          name: 'register',
          component: () => import('@/pages/auth/RegisterView.vue'),
          meta: { title: 'Register' }
        },
        {
          path: 'confirm',
          name: 'confirm',
          component: () => import('@/pages/auth/ConfirmView.vue'),
          meta: { title: 'Confirm email' }
        },
        {
          path: 'reset-password',
          name: 'reset-password',
          component: () => import('@/pages/auth/ResetPasswordView.vue'),
          meta: { title: 'Login' },
        },
        {
          path: '',
          name: 'redirect_login',
          redirect: '/auth/login'
        },
      ],
    },
    {
      path: '/join',
      component: () => import('@/layouts/empty.vue'),
      beforeEnter: checkLogin,
      children: [
        {
          path: '',
          name: 'join',
          component: () => import('@/pages/auth/JoinView.vue'),
        },
      ]
    },
    {
      path: '/:pathMatch(.*)*',
      name: '404',
      component: () => import('@/pages/404/404.vue'),
    },
  ],
  scrollBehavior() {
    return { top: 0 }
  },
})

import loadingStore from '../store/loading';
import { Requester } from '../modules/requester';
import { watch } from 'vue';
import { useUserStore } from '@/store/users';

interface VerifyResponse { 
  status: number
  data: {
    verified: boolean,
    token: string
  }
}
const checkLoginRequester = new Requester('/users/check-token', false)

interface MetaTag {
  name: string;
  content: string
}

router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: Function) => {
  loadingStore.startLoading();
  workspaceId.value = to.params.workspaceId ? to.params.workspaceId.toString() : ''

  // IMPORTANT REFERENCEMENT
  document.title = to.meta.title ? `Jaily |  ${to.meta.title}` : 'Jaily'; // titre de la page
  const head = document.getElementsByTagName('head')[0]; // reset de la meta description
  const existingTags = head.querySelectorAll('meta[name="description"], meta[property^="og:"]'); 
  existingTags.forEach(tag => {
    head.removeChild(tag);
  });

  if (Array.isArray(to.meta.metaTags)) {
    const metaTags = to.meta.metaTags as MetaTag[];
    metaTags.forEach(tag => {
      const element = document.createElement('meta');
      element.setAttribute('name', tag.name);
      element.setAttribute('content', tag.content);
      document.getElementsByTagName('head')[0].appendChild(element);
    });
  }

  next();
});

router.afterEach(() => {
  loadingStore.stopLoading();
});

async function checkLogin(to: RouteLocationNormalized) {
  if (localStorage.getItem('userId') === null ||
    localStorage.getItem('token') === null) {
      if (to.fullPath) localStorage.setItem('next', to.fullPath.toString())
      return { name: 'login'};
    }

  const data: VerifyResponse = await checkLoginRequester.post({
    userId: localStorage.getItem('userId')
  });

  if (data.status === 400 || data.status === 401 || data.status === 404) { 
    localStorage.clear();
    if (to.fullPath) localStorage.setItem('next', to.fullPath.toString())
    return { name: 'login'};
  }

  localStorage.setItem('token', data.data.token);

  if (!data.data.verified) {
    const userStore = useUserStore();
    await userStore.sendCode();
    if (to.fullPath) localStorage.setItem('next', to.fullPath.toString())
    return { name: 'confirm' };
  }
}

async function checkToken(to: RouteLocationNormalized) {

  if (localStorage.getItem('userId') != null) {
    const data: VerifyResponse = await checkLoginRequester.post({
        userId: localStorage.getItem('userId')
    });
    localStorage.setItem('token', data.data.token);
    
    if (data.data.verified && to.name !== 'join') {
      const workspaceStore = useWorkspaceStore();
      await workspaceStore.getWorkspaceList();
      //récupérer le workspaceId pour le passer en params
      if (workspaceId.value) return { name: 'dashboard', params: { workspaceId: workspaceId.value.toString() } };
      return { name: 'no-workspace' };
    }
  }
}

watch(workspaceId, () => {
  if (!workspaceId.value || !router.currentRoute.value.fullPath.includes('/dashboard')) return
  authorize(router.currentRoute.value, router.currentRoute.value, router.replace);
})

export default router
