import { Requester } from '../requester';

export class Stripe {
    static async updatePlansInWorkspace(data: object) {
        const subscriptionsRequester = new Requester('/stripe/subscription', true, true);
        return await subscriptionsRequester.post(data)
    }

    static async createCheckout(data: object) {
        const subscriptionsRequester = new Requester('/stripe/checkout', true, true);
        return await subscriptionsRequester.post(data)
    }

    static async getCustomer(customerId: string) {
        const customerRequester = new Requester('/stripe/customer', true, true);
        const temp: any = {
            "customerId": customerId,
        }
        const params = new URLSearchParams(temp).toString();
        
        return await customerRequester.get(params)
    }

    static async updateCustomer(data: object) {
        const subscriptionsRequester = new Requester('/stripe/customer', true, true);
        return await subscriptionsRequester.patch(data)
    }

    static async createCustomer(data: object) {
        const subscriptionsRequester = new Requester('/stripe/customer', true, true);
        return await subscriptionsRequester.post(data)
    }

    static async transferCheckout(data: object) {
        const subscriptionsRequester = new Requester('/stripe/transfer', true, true);
        return await subscriptionsRequester.post(data)
    }

    static async getSubscriptions(customerId: string) {
        const subscriptionsListRequester = new Requester('/stripe/subscriptions', true, true);
        const temp: any = {
            "customerId": customerId,
        }
        const params = new URLSearchParams(temp).toString();
        
        return await subscriptionsListRequester.get(params)
    }
}

