import { Requester } from '../requester';

export class Workspace {
    static async getWorkspacesList() {
        const requester = new Requester('/workspaces');
        return await requester.get();
    }

    static async updateWorkspace(data: Object) {
        const requester = new Requester('/workspaces', true, true);
        await requester.patch(data)
    }

    static async getUserPermissions() {
        const authRequester = new Requester('/workspaces/permissions', false, true);
        return await authRequester.get()
    }

    static async getMembersInWorkspace(data: object) {
        const membersRequester = new Requester('/workspaces/members', false, true);
        const temp: any = {
            ...data
        }
        const params = new URLSearchParams(temp).toString();
        return await membersRequester.get(params)
    }

    static async inviteMemberInWorkspace(email: string) {
        const membersRequester = new Requester('/workspaces/members', true, true);
        return await membersRequester.post({"email": email})
    }

    static async sendInviteMemberInWorkspace(email: string) {
        const membersRequester = new Requester('/workspaces/members/invitations', true, true);
        return await membersRequester.post({"email": email})
    }

    static async updateMemberInWorkspace(data: object) {
        const membersRequester = new Requester('/workspaces/members', true, true);
        return await membersRequester.patch(data)
    }

    static async deleteMemberInWorkspace(memberId: string) {
        const membersRequester = new Requester('/workspaces/members', true, true);
        return await membersRequester.delete({"member_id": memberId})
    }

    static async acceptWorkspaceInvitation(token: string) {
        const invitRequester = new Requester('/workspaces/members/join', true)
        return await invitRequester.post({ "token": token })
    }

    static async postConfirmDeleteWorkspace(code: string, workspace_id: string) {
        const deleteRequester = new Requester('/workspaces/delete/confirm', true);
        return await deleteRequester.post({ code: code, workspace_id: workspace_id })
    }

    static async getWorkspaceSubscription(workspace_id: string) {
        const authRequester = new Requester('/workspaces/subscriptions', false, true);
        const temp: any = {
            workspace_id: workspace_id
        }
        const params = new URLSearchParams(temp).toString();
        return await authRequester.get(params)
    }

    static async getWorkspacePlans() {
        const authRequester = new Requester('/workspaces/plans', false, true);
        return await authRequester.get()
    }

    static async getDeleteWorkspace() {
        return await new Requester('/workspaces', true, true).delete()
    }

    static async updatePlansInWorkspace(data: object) {
        const plansRequester = new Requester('/workspaces/plans', true, true);
        return await plansRequester.patch(data)
    }

    static async cancelWorkspace() {
        const stripeRequester = new Requester('/workspaces/cancelsubscription', true, true);
        return await stripeRequester.delete()
    }

    static async transferWorkspace(workspaceId: string, selectedMemberId: string) {
        const stripeRequester = new Requester('/workspaces/transfer', true);
        return await stripeRequester.post({"workspace_id": workspaceId, "user_id": selectedMemberId})
    }

    static async cancelRequestSubscription(workspace_id: string) {
        const stripeRequester = new Requester('/workspaces/cancelsubscription', true, true);
        const temp: any = {
            "workspace_id": workspace_id,
        }
        const params = new URLSearchParams(temp).toString();
        return await stripeRequester.get(params)
    }

    static async getIdPlayerRoblox(data: object) {
        const stripeRequester = new Requester('/workspaces/playeridroblox');
        const temp: any = { ...data }
        const params = new URLSearchParams(temp).toString();
        return await stripeRequester.get(params)
    }

    static async leaveWorkspace() {
        const invitRequester = new Requester('/workspaces/members/leave', true, true)
        await invitRequester.post()
    }
}

