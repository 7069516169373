import { defineStore } from 'pinia'
import { ref, Ref, watch } from 'vue';
import { Workspace } from '@/modules/requests/workspaces';
import { Users } from '@/modules/requests/users';
import { Stripe } from '@/modules/requests/stripe';
import { Base64 } from 'base64-string';

export interface WorkspaceType {
    '_id': string,
    name: string,
    icon: string,
    owner_id: string,
    disable: boolean,
    plan: string,
    tutorial: boolean,
    transfer_id: string
}

export interface MembersType {
    members: Array<MemberType>,
    max: string,
    ranks: Array<MembersRanksType>
}

export interface MemberType {
    '_id': string,
    username: string,
    email: string,
    rank: MembersRanksType
}

interface WorkspaceAuthorizeType {
    permissions: Array<string>,
    workspace_id: string,
    user_id: string,
    rank_id: string,
    authorizeDisable: boolean
}

export interface MembersRanksType {
    _id: string,
    name: string
}

export interface InvitationsType {
    invitations: Array<InvitationType>,
    max: string
}

export interface InvitationType {
    email: string,
    date: string
}

interface SubscriptionType {
    workspace_id: string,
    plan: {
        name: string;
        image: string;
        interval_count: number;
    },
    current_period_end: number,
    status?: string,
    canceled_at?: number
}

interface PlansType {
    plan: any,
    originalPrice: number,
    totalOriginalPrice: number,
    disabled: boolean,
    interval: number,
    discountedPrice: number,
    totalDiscountedPrice: number,
    subscriptionId: string
}

export const actualWorkspace: Ref<any> = ref(null);
export const workspaceId: Ref<string> = ref('');
export const userRank: Ref<String | null> = ref(null);
export const workspaceAuthorize: Ref<WorkspaceAuthorizeType> = ref({ permissions: [], workspace_id:'', user_id: '', rank_id: '', authorizeDisable: false })

export const useWorkspaceStore = defineStore('workspace', () => {
    const workspacesList: Ref<Array<WorkspaceType>> = ref([]);
    const membersList: Ref<MembersType> = ref({ 'members': [], 'max': '0', 'ranks': [] });
    const invitationsList: Ref<InvitationsType> = ref({ 'invitations': [], 'max': '0' })
    const workspaceSubscription: Ref<SubscriptionType | null> = ref(null);
    const workspacePlans: Ref<Array<PlansType> | null> = ref(null);
    const workspaceCancel: Ref<any> = ref(null);
    const playerIdRoblox: Ref<any> = ref(null);
    const workspaceTransfer: Ref<any> = ref(null);

    async function getWorkspaceList() {
        const data = await Workspace.getWorkspacesList();

        workspacesList.value = data.workspaces;
        const enc = new Base64();

        for (const workspace of workspacesList.value) {
            workspace.icon = enc.decode(workspace.icon)
        }
        if (data.primary) {
            if (workspaceId.value) actualWorkspace.value = data.workspaces.find((workspace: WorkspaceType) => workspace._id === workspaceId.value);
            else {
                actualWorkspace.value = data.workspaces.find((workspace: WorkspaceType) => workspace._id === data.primary);
                workspaceId.value = data.primary
            }
            
        }
        if (actualWorkspace.value && workspacesList.value.length > 0) workspacesList.value[0]
    }

    async function updateWorkspace(data: Object) {
        await Workspace.updateWorkspace(data);
        await getWorkspaceList();
    }

    async function updatePlansInWorkspace(data: object) {
        await Stripe.updatePlansInWorkspace(data);
        await getWorkspaceList();
        await getWorkspacePlans();
        await getWorkspaceSubscription();
    }

    async function getUserPermissions() {
        const data = await Workspace.getUserPermissions();
        workspaceAuthorize.value = data.permissions;
        if (data['rank']) userRank.value = data['rank']
    }

    async function getWorkspaceSubscription() {
        workspaceSubscription.value = await Workspace.getWorkspaceSubscription(actualWorkspace.value._id);
    }

    async function getWorkspacePlans() {
        workspacePlans.value = await Workspace.getWorkspacePlans();
    }

    async function getDeleteWorkspace() {
        await Workspace.getDeleteWorkspace();
    }

    async function getIdPlayerRoblox(data: object) {
        playerIdRoblox.value = await Workspace.getIdPlayerRoblox(data);
    }

    async function cancelWorkspace() {
        workspaceCancel.value = await Workspace.cancelWorkspace();
    }

    async function transferWorkspace(selectedMemberId: string) {
        workspaceTransfer.value = await Workspace.transferWorkspace(workspaceId.value, selectedMemberId);
    }

    async function cancelRequestSubscription(workspace_id: string) {
        workspaceCancel.value = await Workspace.cancelRequestSubscription(workspace_id);
    }

    async function getMembersInWorkspace(page: number = 0, itemPerPage: number = 10) {
        const data = await Workspace.getMembersInWorkspace({
            'page': page,
            'item_per_page': itemPerPage
        });
        membersList.value = {
            'members': data['members']['data'],
            'max': data['members']['max'],
            'ranks': [{ name: 'Default', '_id': '' }, ...data['members']['ranks']]
        };
        invitationsList.value = {
            'invitations': data['invitations']['data'],
            'max': data['invitations']['max']
        }
    }

    async function updateMemberInWorkspace(memberId: string, rankId: string) {
        const temp = {
            'member_id': memberId,
            'rank_id': rankId
        }
        await Workspace.updateMemberInWorkspace(temp);
    }

    async function deleteMemberInWorkspace(memberId: string) {
        await Workspace.deleteMemberInWorkspace(memberId);
    }

    async function inviteMemberInWorkspace(email: string) {
        await Workspace.inviteMemberInWorkspace(email);
    }

    async function sendInviteMemberInWorkspace(email: string) {
        await Workspace.sendInviteMemberInWorkspace(email);
    }

    async function acceptWorkspaceInvitation(token: string) {
        const data = await Workspace.acceptWorkspaceInvitation(token);
        await getWorkspaceList();
        return data;
    }

    async function postConfirmDeleteWorkspace(code: string) {
        await Workspace.postConfirmDeleteWorkspace(code, actualWorkspace.value['_id']);
    }

    async function leaveWorkspace() {
        await Workspace.leaveWorkspace();
        await getWorkspaceList();
    }

    watch(workspaceId, async () => {
        const newWorkspace = workspacesList.value.filter((workspace) => workspace._id === workspaceId.value)[0]
        if (newWorkspace) {
            actualWorkspace.value = newWorkspace
            await Users.updatePrimaryWorkspace({ 'primary_workspace': actualWorkspace.value['_id'] })
        }
    })

    return {
        workspacesList,
        actualWorkspace,
        membersList,
        invitationsList,
        workspaceSubscription,
        workspacePlans,
        playerIdRoblox,
        workspaceCancel,
        workspaceId,
        workspaceTransfer,
        updatePlansInWorkspace,
        getIdPlayerRoblox,
        acceptWorkspaceInvitation,
        inviteMemberInWorkspace,
        sendInviteMemberInWorkspace,
        getWorkspacePlans,
        getWorkspaceSubscription,
        getWorkspaceList,
        updateWorkspace,
        getUserPermissions,
        getMembersInWorkspace,
        updateMemberInWorkspace,
        deleteMemberInWorkspace,
        cancelWorkspace,
        leaveWorkspace,
        cancelRequestSubscription,
        transferWorkspace,
        getDeleteWorkspace,
        postConfirmDeleteWorkspace
    }
})